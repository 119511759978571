import React from "react"
import RunIcon from "mdi-react/RunIcon"



import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import healthSmartBehavior from "../../images/components/health-smart-behavior.jpg"
import healthSmartBehaviorBody1 from "../../images/components/health-smart-behavior-body-1.jpg"
import healthSmartBehaviorBody2 from "../../images/components/health-smart-behavior-body-2.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./health-smart-behavior-program-customized-for-seniors.scss"

var HealthSmartBehaviorProgramCustomizedForSeniors = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="health-smart-behavior-program-customized-for-seniors-page">
      <Banner
        src={healthSmartBehavior}
        title="Health-Smart Behavior Program Customized for Seniors"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="20%"
      />
      <PageBody className="page">
        <BorderTitle>
          Health-Smart Behavior Program Customized for Seniors
        </BorderTitle>
        <div className="body">
          <img className="left" src={healthSmartBehaviorBody1} alt="" />
          <h4>9-Session Group Discussion Program</h4> <ol> <li> • Designed to increase physical, mental, and spiritual health and wellbeing; social connections; and important aspects of food security, such as knowledge about buying, preparing, and eating healthy food. </li> <li> • 500 seniors at 24 sites in Health Zone 1 participate in nine group discussion sessions and attend interactive panel sessions with health professionals and smartphone training sessions. </li> </ol><h4>Church-Based Food Pantry Program </h4> <ol> <li> • Twenty food pantries operated by faith-based organizations distribute free, healthy food to Health-Smart participants and other community members on a weekly basis. </li> </ol><h4>Physical Activity Sessions </h4> <ol> <li> • Program participants are provided with a list of free or reduced-cost physical activities that are available in the community. </li> </ol><h4>Online Food Security and Social Connection App</h4> <ol> <li> • A website that provides a way for program participants to get health information and resources, connect with each other, and have their health-related questions answered by health care professionals. </li> </ol>
        </div>
        
        <div className="navigation">
        <div className="grow-desktop"/>
          <ComponentNavigator
            next
            link="/components-year-one/program-wide-events-to-promote-physical-and-mental-health-of-seniors"
            icon={RunIcon}
            title="Program-Wide Events to Promote Physical and Mental Health of Seniors"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default HealthSmartBehaviorProgramCustomizedForSeniors
